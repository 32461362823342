const t = (e) => {
  const template = document.createElement("template")
  template.innerHTML = e
  return template.content.firstChild
}

const g = (e) => {
  return document.getElementById(e);
}

const gd = (el, bool) => {
  if (bool) {
    g(el).style.display = "block"
  } else {
    g(el).style.display = "none"
  }
}

const c = (e, classes) => {
  const element = document.createElement(e)
  element.className = classes
  return element
}

const openModal = (element, callback) => {
  var event = new CustomEvent('openModal');
  element.dispatchEvent(event);
  element.addEventListener('modalIsClose', callback);
};

const openModal_v2 = (modal, callback) => {
  const element = g(modal);
  var event = new CustomEvent('openModal');
  element.dispatchEvent(event);

  element.addEventListener('modalIsClose', callback);
};

function closeModal(modal) {
  var event = new CustomEvent('closeModal');
  modal.dispatchEvent(event);
};

export function d() {
  return document
}

export function closeModal_v2(eId) {
  const modal = g(eId)
  var event = new CustomEvent('closeModal');
  modal.dispatchEvent(event);
};

function closeDrawer(modal) {
  const element = g(modal);
  var event = new CustomEvent('closeDrawer');
  element.dispatchEvent(event);
};

export function toast(msg) {
  g('msg').innerText = msg;
  var toast = document.querySelector('.js-toast'), // toast element
    openToastEvent = new CustomEvent('openToast'); // custom event
  // open toast
  toast.dispatchEvent(openToastEvent);
}

function getPref(n) {
  if (localStorage.getItem("pref") != undefined) {
    const pref = JSON.parse(localStorage.getItem("pref"))
    return pref[n]
  } else {
    return null
  }
}

function setPref(n, v, c) {
  if (localStorage.getItem("pref") != undefined) {
    let init = JSON.parse(localStorage.getItem("pref"))
    init[n] = v
    localStorage.setItem("pref", JSON.stringify(init))
    c()
  } else {
    let init = {}
    init[n] = v
    localStorage.setItem("pref", JSON.stringify(init))
    c()
  }
}

function f(e, callback) {
  const form = new FormData(e)
  const formData = {}
  for (const [k, v] of form) { formData[k] = v }
  callback(formData)
}

export function form(eId, callback) {
  g(eId).addEventListener('submit', (e) => {
    e.preventDefault()

    f(e.target, (data) => {
      console.log("fdjdjk");
      callback(data)
    })
  })
}

function isHidden(el) {
  var style = window.getComputedStyle(el);
  return (style.display === 'none')
}

function moveDiv(source, destination) {
  var fragment = document.createDocumentFragment();
  fragment.appendChild(source);
  destination.appendChild(fragment);
}

export function click(eId, callback) {
  g(eId).addEventListener('click', callback)
}

export function click_v2(eId, callback) {
  g(eId).addEventListener('click', (e) => {
    e.preventDefault()
    callback(e)
  })
}

export function q(p) {
  return document.querySelectorAll(p)
}

export function state(params) {

}

export function showToast(msg) {
  var toast = g('toast'), // toast element
    openToastEvent = new CustomEvent('openToast'); // custom event

  g('t_msg').innerText = msg

  // open toast
  toast.dispatchEvent(openToastEvent);
}

export function gp(t, callback) {
  const qString = window.location.search
  const urlParams = new URLSearchParams(qString)

  callback(urlParams.get(t))
}

export function grv_v3(n, callback) {
  const radio = document.querySelectorAll(`input[name="${n}"]`)
  const checkedRadio = radio.checked

  if (checkedRadio !== null) {
    callback(checkedRadio.value)
  }

  radio.forEach(element => {
    element.addEventListener("change", (e) => {
      callback(e.target.value)
    })
  })
}

export function grv_v5(n, callback) {
  const radio = document.querySelectorAll(`input[name="${n}"]`)

  radio.forEach(element => {
    element.addEventListener("change", (e) => {
      callback(e.target.value)
    })
  })
}

export function grv_v4(el, callback) {
  const radio = el
  if (radio.checked) { callback(radio.value) }

  radio.addEventListener("change", (e) => {
    if (e.target.checked) {
      callback(e.target.value)
    }
  })
}

export function setLogos(config) {
  g("logo").src = config.logo
  g("logo_mobile").src = config.logo
  g("logo_footer").src = config.logo
}

export function percentage(percent, total) {
  return (percent / 100) * total
}

export function getPercentage(percent, total) {
  return (percent / 100) * total
}

export function timeFrom(d, X, format) {
  var dates = [];

  switch (format) {
    case "day":
      for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(d.getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)).getDate());
      }
      break
    case "date":
      for (let I = 0; I < Math.abs(X); I++) {
        dates.push(new Date(d.getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000)));
      }
      break
    default:
      break;
  }

  return dates;
}

export function log(msg) {
  console.log(msg);
}

function getSvg(id) {
  switch (id) {
    case "twitter":
      return `<svg class="icon" viewBox="0 0 16 16"><title>Follow us on Twitter</title><g><path d="M16,3c-0.6,0.3-1.2,0.4-1.9,0.5c0.7-0.4,1.2-1,1.4-1.8c-0.6,0.4-1.3,0.6-2.1,0.8c-0.6-0.6-1.5-1-2.4-1 C9.3,1.5,7.8,3,7.8,4.8c0,0.3,0,0.5,0.1,0.7C5.2,5.4,2.7,4.1,1.1,2.1c-0.3,0.5-0.4,1-0.4,1.7c0,1.1,0.6,2.1,1.5,2.7 c-0.5,0-1-0.2-1.5-0.4c0,0,0,0,0,0c0,1.6,1.1,2.9,2.6,3.2C3,9.4,2.7,9.4,2.4,9.4c-0.2,0-0.4,0-0.6-0.1c0.4,1.3,1.6,2.3,3.1,2.3 c-1.1,0.9-2.5,1.4-4.1,1.4c-0.3,0-0.5,0-0.8,0c1.5,0.9,3.2,1.5,5,1.5c6,0,9.3-5,9.3-9.3c0-0.1,0-0.3,0-0.4C15,4.3,15.6,3.7,16,3z"></path></g></svg>`
    case "facebook":
      return `<svg class="icon" viewBox="0 0 32 32">
        <title>Follow us on Facebook</title>
        <path
          d="M32,16A16,16,0,1,0,13.5,31.806V20.625H9.438V16H13.5V12.475c0-4.01,2.389-6.225,6.043-6.225a24.644,24.644,0,0,1,3.582.312V10.5H21.107A2.312,2.312,0,0,0,18.5,13v3h4.438l-.71,4.625H18.5V31.806A16,16,0,0,0,32,16Z">
        </path>
      </svg>`
    case "instagram":
      return `<svg class="icon" viewBox="0 0 32 32"><title>Follow us on Instagram</title><path d="M16,3.7c4,0,4.479.015,6.061.087a6.426,6.426,0,0,1,4.51,1.639,6.426,6.426,0,0,1,1.639,4.51C28.282,11.521,28.3,12,28.3,16s-.015,4.479-.087,6.061a6.426,6.426,0,0,1-1.639,4.51,6.425,6.425,0,0,1-4.51,1.639c-1.582.072-2.056.087-6.061.087s-4.479-.015-6.061-.087a6.426,6.426,0,0,1-4.51-1.639,6.425,6.425,0,0,1-1.639-4.51C3.718,20.479,3.7,20.005,3.7,16s.015-4.479.087-6.061a6.426,6.426,0,0,1,1.639-4.51A6.426,6.426,0,0,1,9.939,3.79C11.521,3.718,12,3.7,16,3.7M16,1c-4.073,0-4.584.017-6.185.09a8.974,8.974,0,0,0-6.3,2.427,8.971,8.971,0,0,0-2.427,6.3C1.017,11.416,1,11.927,1,16s.017,4.584.09,6.185a8.974,8.974,0,0,0,2.427,6.3,8.971,8.971,0,0,0,6.3,2.427c1.6.073,2.112.09,6.185.09s4.584-.017,6.185-.09a8.974,8.974,0,0,0,6.3-2.427,8.971,8.971,0,0,0,2.427-6.3c.073-1.6.09-2.112.09-6.185s-.017-4.584-.09-6.185a8.974,8.974,0,0,0-2.427-6.3,8.971,8.971,0,0,0-6.3-2.427C20.584,1.017,20.073,1,16,1Z"></path><path d="M16,8.3A7.7,7.7,0,1,0,23.7,16,7.7,7.7,0,0,0,16,8.3ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"></path><circle cx="24.007" cy="7.993" r="1.8"></circle></svg>`
    case "github":
      return `<svg class="icon" viewBox="0 0 16 16">
        <title>Follow us on Github</title>
        <g>
          <path
            d="M8,0.2c-4.4,0-8,3.6-8,8c0,3.5,2.3,6.5,5.5,7.6 C5.9,15.9,6,15.6,6,15.4c0-0.2,0-0.7,0-1.4C3.8,14.5,3.3,13,3.3,13c-0.4-0.9-0.9-1.2-0.9-1.2c-0.7-0.5,0.1-0.5,0.1-0.5 c0.8,0.1,1.2,0.8,1.2,0.8C4.4,13.4,5.6,13,6,12.8c0.1-0.5,0.3-0.9,0.5-1.1c-1.8-0.2-3.6-0.9-3.6-4c0-0.9,0.3-1.6,0.8-2.1 c-0.1-0.2-0.4-1,0.1-2.1c0,0,0.7-0.2,2.2,0.8c0.6-0.2,1.3-0.3,2-0.3c0.7,0,1.4,0.1,2,0.3c1.5-1,2.2-0.8,2.2-0.8 c0.4,1.1,0.2,1.9,0.1,2.1c0.5,0.6,0.8,1.3,0.8,2.1c0,3.1-1.9,3.7-3.7,3.9C9.7,12,10,12.5,10,13.2c0,1.1,0,1.9,0,2.2 c0,0.2,0.1,0.5,0.6,0.4c3.2-1.1,5.5-4.1,5.5-7.6C16,3.8,12.4,0.2,8,0.2z">
          </path>
        </g>
      </svg>`
    case "slack":
      return `<svg class="icon" viewBox="0 0 32 32"><title>Follow us on Instagram</title><path d="M16,3.7c4,0,4.479.015,6.061.087a6.426,6.426,0,0,1,4.51,1.639,6.426,6.426,0,0,1,1.639,4.51C28.282,11.521,28.3,12,28.3,16s-.015,4.479-.087,6.061a6.426,6.426,0,0,1-1.639,4.51,6.425,6.425,0,0,1-4.51,1.639c-1.582.072-2.056.087-6.061.087s-4.479-.015-6.061-.087a6.426,6.426,0,0,1-4.51-1.639,6.425,6.425,0,0,1-1.639-4.51C3.718,20.479,3.7,20.005,3.7,16s.015-4.479.087-6.061a6.426,6.426,0,0,1,1.639-4.51A6.426,6.426,0,0,1,9.939,3.79C11.521,3.718,12,3.7,16,3.7M16,1c-4.073,0-4.584.017-6.185.09a8.974,8.974,0,0,0-6.3,2.427,8.971,8.971,0,0,0-2.427,6.3C1.017,11.416,1,11.927,1,16s.017,4.584.09,6.185a8.974,8.974,0,0,0,2.427,6.3,8.971,8.971,0,0,0,6.3,2.427c1.6.073,2.112.09,6.185.09s4.584-.017,6.185-.09a8.974,8.974,0,0,0,6.3-2.427,8.971,8.971,0,0,0,2.427-6.3c.073-1.6.09-2.112.09-6.185s-.017-4.584-.09-6.185a8.974,8.974,0,0,0-2.427-6.3,8.971,8.971,0,0,0-6.3-2.427C20.584,1.017,20.073,1,16,1Z"></path><path d="M16,8.3A7.7,7.7,0,1,0,23.7,16,7.7,7.7,0,0,0,16,8.3ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"></path><circle cx="24.007" cy="7.993" r="1.8"></circle></svg>`
    case "email":
      return `<svg class="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-302L140-685v465h680v-465L480-462Zm0-60 336-218H145l335 218ZM140-685v-55 520-465Z"/></svg>`
    default:
      return ``
  }
}

export function socials(config, el) {
  config.forEach(element => {
    if (element.target === "email") {
      g(el).appendChild(t(`<a href="mailto:${element.email}">${getSvg(element.target)}</a>`))
    } else {
      g(el).appendChild(t(`<a href="${element.link}">${getSvg(element.target)}</a>`))
    }
  });
}

function formTemplate(data) {
  return `<table class="cl-table js-cl-table" aria-label="Column oriented table example">
      <thead class="cl-table__header">
      <tr class="cl-table__row">
          <th class="cl-table__cell cl-table__cell--th" aria-hidden="true"></th>
          <th class="cl-table__cell cl-table__cell--th text-right">Message</th>
          <th class="cl-table__cell cl-table__cell--th text-right">Phone</th>
          <th class="cl-table__cell cl-table__cell--th text-right">Email</th>
          <th class="cl-table__cell cl-table__cell--th text-right">Services</th>
      </tr>
      </thead>
      
      <tbody class="cl-table__body">
      <tr class="cl-table__row">
          <th class="cl-table__cell cl-table__cell--th text-left">${data.name}</th>
          <td class="cl-table__cell text-right">${data.message}</td>
          <td class="cl-table__cell text-right">${data.userPhone}</td>
          <td class="cl-table__cell text-right">${data.email}</td>
          <td class="cl-table__cell text-right">${JSON.stringify(data)}</td>
      </tr>
      </tbody>
  </table>`
}

export function av(param) {
  if (param) {
    return `<svg class="cross-table__icon icon color-success" viewBox="0 0 20 20">
    <title>Option included</title>
    <g fill="currentColor">
        <circle cx="10" cy="10" r="10" opacity=".2" style="isolation:isolate"></circle>
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2" d="M5 10l3 3 7-7"></path>
    </g>
</svg>`
  } else {
    return `<svg class="cross-table__icon icon color-error" viewBox="0 0 20 20">
    <title>Option not included</title>
    <g fill="currentColor">
        <circle cx="10" cy="10" r="10" opacity=".2" style="isolation:isolate"></circle>
        <path d="M6 14l8-8" fill="none" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2"></path>
        <path d="M14 14L6 6" fill="none" stroke="currentColor" stroke-linecap="round"
            stroke-linejoin="round" stroke-width="2"></path>
    </g>
</svg>`
  }
}

function sortObject(o) {
  var sorted = {},
    key, a = [];

  for (key in o) {
    if (o.hasOwnProperty(key)) {
      a.push(key);
    }
  }

  a.sort();

  for (key = 0; key < a.length; key++) {
    sorted[a[key]] = o[a[key]];
  }
  return sorted;
}

export function list(clients) {
  clients.forEach(client => {
    g('clients').appendChild(t(`<li>
        <img src="${client.logo}" class="height-xxxxl radius-lg" alt="">
    </li>`))
  });
}

export function list2(clients) {
  clients.forEach(client => {
    g('clients').appendChild(t(`<li class="col-6@sm col-4@md">
      <a href="#0" class="block margin-bottom-sm">
        <figure class="td-card js-td-card">
          <img class="td-card__img shadow-md radius-lg" src="${client.logo}" alt="Image description">
        </figure>
      </a>
      
      <p class="text-md">
        <a href="#0" class="td-card-link">Nothing is cheap &rarr;</a>
      </p>
    </li>`))
  });
}

export function dis(eId, bool) {
  g(eId).disabled = bool
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

export function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}

var monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export function getLast3Months(n) {
  var today = new Date();
  var last3Months = []

  for (let x = 0; x < n; x++) {
    var m = today.getMonth() - x
    last3Months.push(getFirstDayOfMonth(today.getFullYear(), m));
  }

  last3Months.reverse()

  return last3Months;
}

export function initWallet(projectId, callback) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ "projectId": projectId });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch("https://us-central1-pickaapp-79307.cloudfunctions.net/dashboards", requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log(result);

      const mResult = JSON.parse(result)
      callback(mResult)
    })
    .catch(error => console.log('error', error));
}

export function initU(user){
  const photoURL = user.photoURL;
  if (photoURL !== null) {
    g('profile_image').src = photoURL
  } else {
    g('profile_image').src = "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww"
  }
}

export { t, g, c, f, openModal, openModal_v2, closeModal, closeDrawer, getPref, setPref, isHidden, moveDiv, formTemplate, gd, sortObject, monthNames}