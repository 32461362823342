import './assets/css/style.min.css'
import { form, formTemplate, g, list, t } from '../../utils';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDoc, doc, addDoc, serverTimestamp } from 'firebase/firestore'
import { firebaseConfig, system } from './components/config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore()

// Get a reference to the loader's div
var loaderDiv = g("loader-wrapper");
// When the transition ends remove loader's div from display
// so that we can access the map with gestures or clicks
loaderDiv.addEventListener("transitionend", function () {
    loaderDiv.style.display = "none";
}, true);


const removeSplash = () => {
    // Kick off the CSS transition
    g("loader-img").classList.add("anim-off")
    loaderDiv.style.opacity = 0.0;
}

const getLink = (id) => {
    if (id) { return `<a class="link" href="${id}">Learn more →</a></p>` }
    else { return "" }
}

system((config) => {
    const hero = config.hero

    g('heading').innerText = hero.name
    g('remark').innerText = hero.description
    
    console.log(hero.figure);
    g('hero').src = hero.figure
    g("photo_credit").appendChild(t(`<p>${hero.photoCredit}</p>`))

    const contactActions = config.contactActions
    contactActions.forEach(action => {
        g('action').appendChild(t(action))
    });

    list(config.clients)

    const services = config.services
    services.forEach(service => {
        g('sticky_features').appendChild(t(`<li class="sticky-feature__media-item js-sticky-feature__media-item">
            <figure class="sticky-feature__media-figure">
                <img src="${service.figure}" alt="Image description">
            </figure>
        </li>`))

        g('sticky_features_mobile').appendChild(t(`<li class="js-sticky-feature__content-item text-component">
            <h3 class="sticky-feature__title js-sticky-feature__title">${service.name}</h3>
            
            ${service.descriptionBody}

            ${getLink(service.pageId2)}

            <figure class="sticky-feature__content-figure">
                <img src="${service.figure}" alt="Image description">
            </figure>
        </li>`))

        g('form_services').appendChild(t(`<li>
            <input class="checkbox" type="checkbox" id="id_${service.name}" name="${service.name}">
            <label for="id_${service.name}">${service.name}</label>
        </li>`))
    });

    const solutions = config.solutions
    solutions.forEach(solution => {
        g("_solutions").appendChild(t(`<div class="col-6@md">
            <div class="flex flex-column bg-light inner-glow shadow-sm shadow-ring padding-md radius-lg overflow-hidden">
            <div class="margin-bottom-md">
                <h3>${solution.name}</h3>

                <p class="color-contrast-medium margin-y-xs line-height-md">${solution.description}</p>

                <div class="flex flex-wrap gap-xs">
                    <a class="btn btn--subtle" href='${solution.infoPage}'>Learn more</a>
                    <a class="btn btn--primary" href='${solution.getStartedPage}'>${solution.action}</a>
                </div>
            </div>

            <figure class="margin-top-auto">
                <img class="block width-100% radius-lg" src="${solution.figure}" alt="Image description">
            </figure>
            </div>
        </div>`))
    });

    g('contact').style.backgroundImage = `url('${config.bgContact}')`

    form('contact', (data) => {
        g('btn_submit').disabled = true

        addDoc(
            collection(firestore, "mail"),
            {
                to: [
                    "pick@pickanapp.com",
                    "mbkcust@gmail.com",
                    "mwila@duck.com"
                ],
                message: {
                    subject: "Form Submission",
                    text: JSON.stringify(data),
                    html: formTemplate(data)
                },
                timestamp: serverTimestamp(),
                newsletter: data.newsletter
            }
        ).then(() => {
            alert("message sent!")
            location.reload()
        })
    })

    const testimonials = config["testimonials"]
    testimonials.forEach(testimonial => {
        g("testimonials").appendChild(t(`<div class="bg-contrast-lower bg-opacity-30% radius-md padding-md flex@md flex-column@md col-4@md">
            <svg class="icon icon--xl color-contrast-low margin-bottom-xxs" aria-hidden="true" width="64" height="64" viewBox="0 0 64 64">
            <polygon points="2 36 17 2 26 2 15 36 26 36 26 62 2 62 2 36" />
            <polygon points="38 36 53 2 62 2 51 36 62 36 62 62 38 62 38 36" />
            </svg>

            <blockquote class="line-height-md margin-bottom-md">${testimonial.comment}</blockquote>
            
            <footer class="flex flex-wrap items-center margin-top-auto@md">
            <figure class="flex-shrink-0 margin-right-xxs" aria-hidden="true">
                <img class="block width-lg height-lg radius-50% border border-2 border-bg" src="${testimonial.figure}" alt="James Powell">
            </figure>

            <cite class="text-sm">
                <strong>${testimonial.name}</strong> 
                <span class="block color-contrast-medium margin-top-xxxxs">${testimonial.title} at ${testimonial.company}</span>
            </cite>
            </footer>
        </div>`))
    });

    const qa = config["Q&A"]
    qa.forEach(element => {
        g('q_a').appendChild(t(`<li class="text-points__item col-6@md">
            <div class="text-points__text text-component line-height-lg text-space-y-lg">
                <h4>
                    <span class="text-points__bullet" aria-hidden="true"></span>${element.question}
                </h4>

                <p class="color-contrast-low">${element.answer}</p>
            </div>
        </li>`))
    });

    removeSplash()
})

require('./assets/js/scripts')
require('./assets/js/color-theme')